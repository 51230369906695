const _ = require('lodash');
const localStorage = require('../utils/localStorage/LocalStorage');
const ServerConfig = require('../ServerConfig');
const Account = require('../authentication/Account');
const RealtimeServer = require('../RealtimeServer');

const LOCAL_STORAGE_PRINTED_ADS_KEY = 'printedAds';
const PRINTED_ADS_TIME_TO_LIVE_DURATION_IN_MS = 3600e3;
let realEstateAdIds = [];
let pendingRequest = false;

module.exports = {
    canPrintRealEstateAd,
    collectAdsPrints,
};

const delayInMsBeforeSendingAdsPrints = _.get(ServerConfig.config, 'delayInMsBeforeSendingAdsPrints');
const collectPrintsDebounced = _.debounce(() => {
    if (!_.isEmpty(realEstateAdIds) && !pendingRequest) {
        pendingRequest = true;
        Account.getAccountAndCreateGuestIfNeeded((err, account) => {
            pendingRequest = false;
            if (err) {
                console.error('Could not get account info');
            } else {
                addPrintedAdInLocalStorage(realEstateAdIds);
                RealtimeServer.emit('ads:prints', {
                    useRedisList: true,
                    realEstateAdIds,
                    accountId: account.id,
                });
                realEstateAdIds = [];
            }
        });
    }
}, delayInMsBeforeSendingAdsPrints);

function canPrintRealEstateAd(realEstateAdId) {
    //make sure all expired ads are removed from list
    const printedAdIdsList = cleanExpiredPrintedAdAndGetPrintedAdsList();
    return !_.includes(printedAdIdsList, realEstateAdId);
}

function addPrintedAdInLocalStorage(realEstateAdIds) {
    const now = new Date();
    const printedAdsInLocalStorage = JSON.parse(localStorage.getValue(LOCAL_STORAGE_PRINTED_ADS_KEY)) || {};
    for (const realEstateAdId of realEstateAdIds) {
        printedAdsInLocalStorage[realEstateAdId] = now.getTime() + PRINTED_ADS_TIME_TO_LIVE_DURATION_IN_MS;
    }
    localStorage.setValue(LOCAL_STORAGE_PRINTED_ADS_KEY, JSON.stringify(printedAdsInLocalStorage));
}

function cleanExpiredPrintedAdAndGetPrintedAdsList() {
    const now = new Date();
    const validPrintedAds = {};
    const printedAdsInLocalStorage = JSON.parse(localStorage.getValue(LOCAL_STORAGE_PRINTED_ADS_KEY)) || {};
    _.each(printedAdsInLocalStorage, (printedAdExpirationDate, printedAdId) => {
        if (now.getTime() < printedAdExpirationDate) {
            validPrintedAds[printedAdId] = printedAdExpirationDate;
        }
    });
    localStorage.setValue(LOCAL_STORAGE_PRINTED_ADS_KEY, JSON.stringify(validPrintedAds));
    return _.keys(validPrintedAds);
}

function collectAdsPrints(realEstateAdId) {
    // remove duplicate real estate ad ids
    realEstateAdIds = _.union(realEstateAdIds, [realEstateAdId]);
    collectPrintsDebounced();
}
