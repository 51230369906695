const _ = require('lodash');
const {i18n: {translate}} = require('fack');

const Account = require('../authentication/Account');
const RealtimeServer = require('../RealtimeServer');
const GoogleTagManager = require('../stats/GoogleTagManager');
const {EventEmitter} = require('events');
const SearchCriteria = require('../utils/SearchCriteria');
const Views = require('../views/Views');
const BrowserNotifications = require('../notifications/BrowserNotifications');
const {commit} = require('../store');

const {
    REMOVE_COUNT_OF_NEW_ADS_FOR_SAVED_SEARCH,
    SET_COUNT_OF_NEW_ADS_FOR_SAVED_SEARCH,
    SET_COUNT_OF_NEW_ADS_FOR_SAVED_SEARCHES,
} = require('../store/Mutations');

const SavedSearches = _.extend(new EventEmitter(), {
    init,
    getAll,
    getOneById,
    create,
    updateFrequency: _.partial(_update, '/updateSearch'),
    updateCriteria: _.partial(_update, '/updateSearchCriteria'),
    updateSearch: _.partial(_update, '/updateSearch'),
    deactivate,
    findMatchingSavedSearch,
    getSavedSearchesCount,
    getTotalSavedSearchesCount,
    updateLastInteractionDate,
    clearSavedSearchWebNotifications,
});

module.exports = SavedSearches;

let _savedSearches;
let _savedSearchesCount = {};
let _totalSavedSearchesCount = 0;
let lastAccountId;

function handleAccountChange(account, cb = _.noop) {
    //if account Id did not change, the RealtimeServer won't send the stats.
    //we need a way to query those dynamically
    if (account == null || lastAccountId != account.id) {
        _savedSearchesCount = {};
        _savedSearches = [];
        lastAccountId = account && account.id;
        loadAccountSearchesWithRetry(account, cb);
    } else {
        cb(null, _savedSearches);
    }
}

function loadAccountSearchesWithRetry(account, cb, iteration = 0) {
    if (account && lastAccountId === account.id) { // to stop retry on account change
        loadAccountSearches(account, (err, savedSearches) => {
            if (err && err.statusCode >= 500) {
                iteration++;
                console.error(`Could not fetch savedSearches (iteration ${iteration}), retrying in 1 second`, err);
                _.delay(loadAccountSearchesWithRetry, 1000, account, cb, iteration);
            } else {
                cb(err, savedSearches);
            }
        });
    } else if (account == null) {
        // loadAccountSearches is synchronous in case of null account
        loadAccountSearches(null, cb);
    } else { // lastAccountId != account.id
        // stop retrying, account changed
    }
}

function loadAccountSearches(account, cb = _.noop) {
    if (account) {
        getAccountSearches(account, function (err, searches) {
            _savedSearches = searches || [];
            SavedSearches.emit('savedSearchesLoaded');
            cb(err, _savedSearches);
        });
    } else {
        _savedSearches = [];
        _.defer(() => {
            SavedSearches.emit('savedSearchesLoaded');
            cb(null, _savedSearches);
        });
    }
}

function getSavedSearchesCount() {
    return _savedSearchesCount;
}

function getTotalSavedSearchesCount() {
    _totalSavedSearchesCount = 0;
    _.each(_savedSearchesCount, function (num, key) {
        _totalSavedSearchesCount += _savedSearchesCount[key];
    });
    return _totalSavedSearchesCount;
}

function init() {
    clearEvents();
    RealtimeServer.on('savedSearchesCount', function ({savedSearchesCount}) {
        commitToSavedSearchesStore(SET_COUNT_OF_NEW_ADS_FOR_SAVED_SEARCHES, savedSearchesCount);
        updateSavedSearchesCount(savedSearchesCount);
        SavedSearches.emit('savedSearchesCountChanged');
    });
    RealtimeServer.on('newAdInSavedSearches', function (message) {
        _.each(message.savedSearchesCount, function (count, searchId) {
            commitToSavedSearchesStore(SET_COUNT_OF_NEW_ADS_FOR_SAVED_SEARCH, {
                savedSearchId: searchId,
                newAdsCount: count,
            });
            _savedSearchesCount[searchId] = count;
        });
        SavedSearches.emit('savedSearchesCountChanged');
    });
    RealtimeServer.on('clearSavedSearchQueue', function ({id}) {
        commitToSavedSearchesStore(REMOVE_COUNT_OF_NEW_ADS_FOR_SAVED_SEARCH, id);
        clearSearchCounter(id);
        SavedSearches.emit('savedSearchesCountChanged');
    });
    Account.on('change', loadCurrentAccountSearches);
    Account.on('forgetAccount', forgetCounts);
    loadCurrentAccountSearches();
}

function commitToSavedSearchesStore(mutation, value) {
    commit(`savedSearches/${mutation}`, value);
}

function loadCurrentAccountSearches() {
    handleAccountChange(Account.getAuthenticatedAccount());
}

function forgetCounts() {
    handleAccountChange(null, function () {
        SavedSearches.emit('savedSearchesCountChanged');
    });
}

function clearEvents() {
    RealtimeServer.off('savedSearchesCount');
    RealtimeServer.off('newAdInSavedSearches');
    RealtimeServer.off('clearSavedSearchQueue');
    Account.removeListener('forgetAccount', forgetCounts);
}

function clearSearchCounter(id) {
    if (_savedSearchesCount) {
        delete _savedSearchesCount[id];
    }
}

function updateSavedSearchesCount(savedSearchesCount) {
    //reinit it all to remove old counts
    _savedSearchesCount = savedSearchesCount;
}

function updateCachedSearches(cb) {
    loadAccountSearches(Account.getAuthenticatedAccount(), cb);
}

function afterSearchSaved({searchCriteria, pushNotificationMode, emailNotificationMode, intention}) {
    const {
        locationNames,
        ...rest
    } = _.omit(searchCriteria, [
        'onTheMarket',
        'zoneIdsByTypes',
    ]);

    GoogleTagManager.sendEvent('searchSaved', {
        locations: locationNames,
        pushNotificationMode,
        emailNotificationMode,
        intention,
        ...rest,
    });

    if (pushNotificationMode !== 'never') {
        BrowserNotifications.enablePushNotifications({silent: true}, err => {
            if (err) {
                Views.volatileFeedback.showError(translate('savedSearches.enablePushNotificationsError'));
                console.error(JSON.stringify(err));
            }
        });
    }
}

function create(search, callback) {
    Account.getAccountAndCreateGuestIfNeeded(() => {
        callback = wrapMutationCallback(callback);
        const cleanSearch = getCleanSearch(search);
        Account.postJson({
            url: '/saveSearch',
            data: {
                search: cleanSearch,
                access_token: Account.getAccessToken(),
                referrer: Account.getReferrer(),
            },
            disableErrorPage: true,
            serverErrorMessage: 'saveSearch',
            callback: function (err, data) {
                if (!err && data) {
                    afterSearchSaved(cleanSearch);
                }
                callback(err, data);
            },
        });
    });
}

function _update(url, search, callback) {
    callback = wrapMutationCallback(callback);
    Account.postJson({
        url,
        timeout: 30000, //30 seconds
        data: {
            search: getCleanSearch(search),
            access_token: Account.getAccessToken(),
            referrer: Account.getReferrer(),
            id: search._id,
        },
        disableErrorPage: true,
        callback,
    });
}

function getCleanSearch(search) {
    const searchCriteria = SearchCriteria.clean(search.searchCriteria);
    return _.extend({}, search, {searchCriteria}); //do not modify parameter
}

function getAccountSearches(account, callback) {
    Account.authAjax({
        url: '/savedSearches',
        timeout: 30000, //30 seconds
        data: {
            id: account.id,
        },
        disableErrorPage: true,
        serverErrorMessage: 'savedSearches',
        callback,
    });
}

function getAll(callback) {
    // update the cache in case of update from another session
    loadAccountSearches(Account.getAuthenticatedAccount(), callback);
}

function getOneById(_id, options, cb) {
    Account.authAjax({
        url: '/savedSearchById.json',
        timeout: 30000, //30 seconds
        data: _.extend({_id}, options),
        disableErrorPage: true,
        serverErrorMessage: 'getOneById',
        callback: function (err, data) {
            if (!err && data) {
                SavedSearches.emit('savedSearchesCountChanged');
            }
            cb(err, data);
        },
    });
}

function deactivate(id, callback) {
    callback = wrapMutationCallback(callback);
    Account.postJson({
        url: '/deactivateSavedSearch',
        timeout: 30000, //30 seconds
        data: {
            access_token: Account.getAccessToken(),
            id,
        },
        disableErrorPage: true,
        serverErrorMessage: 'deactivateSavedSearch',
        callback: function (err) {
            callback(err, id);
        },
    });
}

function wrapMutationCallback(cb) {
    return function (err, param) {
        updateCachedSearches(function (updateErr) {
            cb(err || updateErr, param);
        });
    };
}

function findMatchingSavedSearch(searchCriteria) {
    const cleanFilters = SearchCriteria.clean(searchCriteria);
    return _.find(_savedSearches, function (savedSearch) {
        const cleanSavedFilters = SearchCriteria.clean(savedSearch.searchCriteria);
        return _.isEqual(cleanSavedFilters, cleanFilters);
    });
}

function updateLastInteractionDate(savedSearchId, cb) {
    getOneById(savedSearchId, {
        updateLastInteractionDate: true,
    }, cb);
}

function clearSavedSearchWebNotifications(savedSearchId, callback) {
    if (Account.isAuthenticated()) {
        Account.authAjax({
            method: 'DELETE',
            url: `/clearSavedSearchWebNotifications/${savedSearchId}`,
            callback,
        });
    } else {
        _.defer(callback);
    }
}
