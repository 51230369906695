const _ = require('lodash');

const AddLinkIfNeeded = require('./AddLinkIfNeeded');
const RealEstateAdNotificationBoostButton = require('../../../notificationBoost/components/RealEstateAdNotificationBoostButton');
const RealEstateAdTitleGenerator = require('../../../../common/RealEstateAdTitleGenerator');
const isSoldRealEstateAd = require('../../../utils/isSoldRealEstateAd');
const Account = require('../../../authentication/Account');
const ProgrammeHelper = require('../../../../common/ProgrammeHelper');
const {canMarkAdsAsLeading, canHighlightAds} = require('../../../setFeatured');
const {canBoostAd} = require('../../../notificationBoost');
const {canPrintRealEstateAd, collectAdsPrints} = require('../../CollectPrintedAdHelper');
const ApplicationConfig = require('../../../../js/app/ApplicationConfig');
const canOpenAdDetail = require('../../../utils/canOpenAdDetail');

const SORT_BY_LIST_TO_DISPLAY_RELEVANCE = [
    'relevance',
    'relevanceDev',
];
const THRESHOLD_TO_COLLECT_AD_PRINT = 0.85;

// @vue/component
module.exports = {
    components: {
        AddLinkIfNeeded,
        RealEstateAdNotificationBoostButton,
    },
    props: {
        realEstateAd: {
            type: Object,
            required: true,
        },
        sortBy: {
            type: String,
            default: undefined,
            SORT_BY_LIST_TO_DISPLAY_RELEVANCE,
        },
        lastViewDateForSearch: {
            type: String,
            default: null,
        },
        ofTheLocationText: {
            type: String,
            default: null,
        },
        disableAlreadySeen: Boolean,
        displayedAsHighlighted: Boolean,
        enableLastContactDateTag: Boolean,
        isRowWidthUnderStandardValue: Boolean,
        displayFavorite: Boolean,
        statsEnabled: Boolean,
        adPrintCollectEnabled: Boolean,
    },
    data() {
        return {
            realEstateAdsPrintsObserver: null,
        };
    },
    computed: {
        canPrintAd() {
            const {status: {onTheMarket}} = this.realEstateAd;
            return !ApplicationConfig.applicationPro && this.adPrintCollectEnabled && onTheMarket &&
                !isSoldRealEstateAd(this.realEstateAd);
        },
        generatedTitleWithHighlight() {
            return RealEstateAdTitleGenerator.getTitle(
                this.realEstateAd,
                'adOverviewTitle'
            );
        },
        realEstateAdLink() {
            return canOpenAdDetail(this.realEstateAd)
                ? this.realEstateAd.detailedSheetUrl
                : null;
        },
        realEstateAdDescription() {
            return this.realEstateAd.description;
        },
        displayRelevance() {
            return _.includes(SORT_BY_LIST_TO_DISPLAY_RELEVANCE, this.sortBy) && this.isAdsRelevanceViewer;
        },
        realEstateAdId() {
            return this.realEstateAd.id;
        },
        realEstateAdOnTheMarket() {
            return this.realEstateAd.status.onTheMarket;
        },
        isAdsRelevanceViewer() {
            return Account.isAdsRelevanceViewer();
        },
        isNotLot() {
            const isLot = ProgrammeHelper.isLot(this.realEstateAd);
            return !isLot;
        },
        displayMarkAsLeadingAdButton() {
            return canMarkAdsAsLeading(this.realEstateAd) && this.isNotLot;
        },
        canBuyTemporaryLeadingAds() {
            return Account.canBuyTemporaryLeadingAds();
        },
        displayHighlightAdButton() {
            return canHighlightAds(this.realEstateAd) && this.isNotLot;
        },
        canBoostAds() {
            return canBoostAd(this.realEstateAd) && this.isNotLot;
        },
        canChangeOnTheMarket() {
            return this.realEstateAd.userRelativeData.canChangeOnTheMarket;
        },
        canModifyAd() {
            return this.realEstateAd.userRelativeData.canModifyAd;
        },
        canModifyAdBlur() {
            return this.realEstateAd.userRelativeData.canModifyAdBlur;
        },
        displayStats() {
            return this.realEstateAd.userRelativeData.canSeeStats && this.statsEnabled;
        },
    },
    watch: {
        adPrintCollectEnabled(newAdPrintCollectEnabled) {
            if (newAdPrintCollectEnabled) {
                this.collectRealEstateAdsPrints();
            } else if (this.realEstateAdsPrintsObserver != null) {
                this.realEstateAdsPrintsObserver.disconnect();
                this.realEstateAdsPrintsObserver = null;
            }
        },
    },
    mounted() {
        this.collectRealEstateAdsPrints();
    },
    methods: {
        onAdClick(event) {
            this.$emit('click-on-ad', event);
        },
        onBackOnTheMarket(modifiedAd) {
            this.$emit('back-on-the-market', modifiedAd);
        },
        collectRealEstateAdsPrints() {
            const {realEstateAd: {id: realEstateAdId}, $el} = this;
            if (this.canPrintAd) {
                if ('IntersectionObserver' in window) {
                    const intersectionObserverOption = {
                        threshold: [THRESHOLD_TO_COLLECT_AD_PRINT],
                    };
                    this.realEstateAdsPrintsObserver = new IntersectionObserver((entries) => {
                        if (entries[0].intersectionRatio >= THRESHOLD_TO_COLLECT_AD_PRINT && entries[0].isIntersecting
                            && canPrintRealEstateAd(realEstateAdId)) {
                            collectAdsPrints(realEstateAdId);
                        }
                    }, intersectionObserverOption);
                    this.realEstateAdsPrintsObserver.observe($el);
                } else {
                    console.warn('Collect prints disabled as browser is not compatible with IntersectionObserver');
                }
            }
        },
    },
};
